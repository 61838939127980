import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Head from "../components/head"

import slugify from '../util/slugify'

import basics from '../../ben-edit/basics'
import categories from '../../ben-edit/categories'

export default () => {
  const subtitle = basics.subtitle

  return (
    <Layout noWidth="true">
      <Head />

      <div
        css={css`
          text-align: center;
          padding: 1rem 2rem;
          padding-bottom: 0;
          max-width: 768px;
          margin: 0 auto;
          margin-bottom: 3rem;
        `}
      >
        <p
          css={css`
            line-height: 1.5;
          `}
        >
          {subtitle}
        </p>
      </div>

      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-row-gap: 1.5rem;
          grid-column-gap: 2rem;
          margin-bottom: 2rem;

          @media (max-width: 900px) {
            grid-template-columns: 1fr;
          }
        `}
      >
        {categories.map(category => (
          <Link
            key={category.name}
            to={`/${slugify(category.name)}/`}
            css={css`
              text-decoration: none;
            `}
          >
            <div
              css={css`
                width: 100%;
                background-color: var(--background-light);
                padding: .8rem;
                padding-bottom: 1rem;
                box-shadow: 2px 4px 16px black;

                transition: box-shadow .2s;

                :hover {
                  box-shadow: 2px 4px 16px hsl(0, 0%, 10%);
                }
              `}
            >
              <div
                css={css`
                  background-image: url(${category.thumbnail});
                  background-size: cover;
                  background-position: center center;
                  height: 100%;
                  height: 240px;
                  box-shadow: 2px 4px 8px hsla(0, 0%, 0%, .15);
                `}
              />

              <p
                css={css`
                  text-align: center;
                  margin-top: .7rem;
                  color: var(--text-light);
                  letter-spacing: .03rem;
                  font-weight: 500;
                `}
              >
                {category.name}
              </p>
            </div>
          </Link>
        ))}
      </div>



    </Layout>
  )
}
